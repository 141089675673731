import { useFormik, validateYupSchema } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import alertAwareLogo from "../../../src/alertaware_logo_large.png";
import Moment from "moment";
import { useNavigate } from "react-router-dom";

// For dev : https://9n3q6h7pi5.execute-api.us-east-2.amazonaws.com/Prod
// For qa : https://n0guahsv6i.execute-api.us-west-2.amazonaws.com/Prod
// For prod : https://tjd6dn32xd.execute-api.us-east-2.amazonaws.com/prod

const DEV_URL = "https://9n3q6h7pi5.execute-api.us-east-2.amazonaws.com/Prod";
const QA_URL = "https://notification-api-qa.alertaware.com";
const STAGING_URL = "https://notification-api-staging.alertaware.com";
const PROD_URL = "https://notification-api-staging.alertaware.com";

// NOTE : Depending on the environment change the base url
const baseURL = STAGING_URL;

async function fetchNotification(id: string) {
  const url = `${baseURL}/get-status?id=${id}`;
  const response = await fetch(url);
  const body = response.json();

  return body;
}

async function updateNotification(
  id: string,
  userResponse: string,
  comment: string,
  userResponseCode: string
) {
  const url = `${baseURL}/update-response?id=${id}`;
  const now = new Date();

  const body = {
    replied_at: now,
    confirmed_at: now,
    replied_note: comment,
    reply: userResponse,
    reply_code: userResponseCode,
  };

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "no-cors",
  });
  return response;
}

function renderPageLoadingView() {
  return (
    <div className="h-screen w-full flex items-center justify-center">
      {renderSpinner()}
      <div className="text-xl font-bold text-black-500">
        Loading Notifications
      </div>
    </div>
  );
}

function renderSpinner() {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-orange-500"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
}

function getCodeFromResponse(response: string, pollOptions: any[]) {
  let code = "";
  for (let option of pollOptions) {
    if (option.description == response) {
      code = option.code;
    }
  }

  return code;
}

function PollDisplayer() {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const notificationId: string = queryParameters.get("id") ?? "";

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      comment: "",
      selectedAnswer: "",
      pollOptions: [],
      attachement: [],
      repliedAt: "",
      organizationName: "",
      organizationSmallLogo: alertAwareLogo,
      createdAt: "",
      hide_comment_box: false,
      confirmed_at: null,
    },
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: async (values) => {
      setLoading(true);
      let responseCode = getCodeFromResponse(
        formik.values.selectedAnswer,
        formik.values.pollOptions
      );
      await updateNotification(
        notificationId,
        formik.values.selectedAnswer,
        formik.values.comment,
        responseCode
      );

      setLoading(false);
      navigate(`/success?id=${notificationId}`);
    },
  });

  useEffect(() => {
    fetchNotification(notificationId).then((v) => {
      if (v.uuid === undefined) {
        // navigate(`/not-found`);
      } else {
        if (formik.values.title === "" || formik.values.description === "") {
          formik.setFieldValue("title", v.notification_detail.title);
          formik.setFieldValue("description", v.notification_detail.body);
          formik.setFieldValue(
            "organizationSmallLogo",
            v?.organization_banner_logo ?? alertAwareLogo
          );
          formik.setFieldValue("organizationName", v?.organization_name);

          // before rendering the response option
          let responseOptions = v.notification_detail.responseOptions;

          // check if the response options length
          if (responseOptions?.length) {
            responseOptions.sort((a: any, b: any) => {
              const aCode = a?.code;
              const bCode = b?.code;

              // Attempt to parse the codes as integers
              const aParsed = parseInt(aCode);
              const bParsed = parseInt(bCode);

              // Check if both codes are parsable to integers
              const aIsNumber = !isNaN(aParsed);
              const bIsNumber = !isNaN(bParsed);

              if (aIsNumber && bIsNumber) {
                // If both are numbers, sort numerically
                return aParsed - bParsed;
              } else if (aIsNumber) {
                // If only a is a number, it should come first
                return -1; // a comes first
              } else if (bIsNumber) {
                // If only b is a number, it should come first
                return 1; // b comes first
              } else {
                // If neither are numbers, sort as strings
                return aCode?.localeCompare(bCode);
              }
            });
          }

          formik.setFieldValue("pollOptions", responseOptions);
          formik.setFieldValue(
            "attachement",
            v.notification_detail.attachements
          );
          formik.setFieldValue("selectedAnswer", v.reply);
          formik.setFieldValue("comment", v.replied_note);
          formik.setFieldValue("repliedAt", v.replied_at);
          formik.setFieldValue("createdAt", v.created_at);
          formik.setFieldValue("hide_comment_box", v.hide_comment_box);
          formik.setFieldValue("confirmed_at", v.confirmed_at);

          setPageLoading(false);
        }
        document.title =
          v?.notification_detail?.responseOptions?.length > 0
            ? "Answer Poll"
            : "Confirm Message";
      }
    });
  }, []);

  function renderConfirmed() {
    if (formik.values.confirmed_at !== null && formik.values.hide_comment_box) {
      return (
        <div className="mt-5 flex items-center">
          <svg
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" fill="green"></circle>
            <path
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
          <span className="ml-2 text-green-600 font-bold">Confirmed</span>
        </div>
      );
    }
  }
  function renderLastUpdate() {
    if (
      formik.values.repliedAt !== undefined &&
      formik.values.repliedAt !== "" &&
      formik.values.repliedAt !== null
    ) {
      return (
        <div>
          <p className="text-sm text-gray-500">
            Last reply :{" "}
            {Moment(formik.values.repliedAt).format(
              "dddd, MMMM Do YYYY, h:mm a"
            )}
          </p>

          {renderConfirmed()}
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  function renderPollOptions() {
    if (formik.values.pollOptions)
      return (
        <div>
          {formik.values.pollOptions.map((v: any) => {
            return (
              <div
                id={v.description}
                key={v.description}
                onClick={(e) => {
                  formik.setFieldValue("selectedAnswer", v.description);
                }}
              >
                <input
                  className="sr-only peer"
                  type="radio"
                  id={v.description}
                  checked={v.description === formik.values.selectedAnswer}
                  value={v.description}
                  onChange={(e) => {
                    console.log(e);
                  }}
                  key={v.description}
                />

                <label className="flex break-all p-2 my-2 bg-white border border-gray-300 rounded-lg cursor-pointer text-gray-600 peer-checked:text-gray-950 peer-checked:font-semibold focus:outline-none hover:bg-gray-50 peer-checked:ring-orange-500 peer-checked:ring-2 peer-checked:border-transparent whitespace-normal">
                  {v.description}
                </label>
              </div>
            );
          })}
        </div>
      );
  }

  function renderAttachments() {
    if (formik.values.attachement.length > 0) {
      return (
        <div>
          <p className="text-sm text-gray-500 pt-4 font-bold">Attachment</p>
          {formik.values.attachement.map((v: any) => {
            return (
              <a
                key={v.url}
                target="_blank"
                className="text-sm text-orange-500 underline"
                href={v.url}
              >
                link
              </a>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  function isDisabled() {
    if (
      formik.values.pollOptions !== undefined &&
      formik.values.pollOptions.length > 0
    ) {
      return (
        formik.values.selectedAnswer === undefined ||
        formik.values.selectedAnswer === "" ||
        formik.values.selectedAnswer === null
      );
    } else if (loading) {
      return true;
    }
    // the user already confirmed for a no comment message
    else {
      return false;
    }
  }

  function renderPollError() {
    if (
      formik.values.pollOptions !== undefined &&
      formik.values.pollOptions.length > 0
    ) {
      if (
        formik.values.selectedAnswer === undefined ||
        formik.values.selectedAnswer === "" ||
        formik.values.selectedAnswer === null
      ) {
        return <p>Please select an option</p>;
      }
    }
  }

  function buttonView() {
    if (
      formik.values.confirmed_at !== null &&
      formik.values.hide_comment_box &&
      formik.values.pollOptions.length === 0
    ) {
      return <div></div>;
    }
    return (
      <button
        type="submit"
        disabled={isDisabled() || loading}
        className="bg-orange-500 disabled:bg-gray-400 font-bold text-sm text-white py-3 mt-6 rounded-lg w-full col-span-5 "
      >
        {renderButtonChild()}
      </button>
    );
  }

  function buttonMessage() {
    if (loading) {
      return "Submitting...";
    } else if (
      formik.values.pollOptions &&
      formik.values.pollOptions.length > 0
    ) {
      return "Submit Your Answer";
    } else if (
      !formik.values.hide_comment_box &&
      formik.values.confirmed_at !== null
    ) {
      return "Update Your Answer";
    } else {
      return "Confirm Message";
    }
  }
  function renderButtonChild() {
    return (
      <div className="flex items-center justify-center">
        {loading ? renderSpinner() : <div></div>}

        <div className="pl-2">{buttonMessage()}</div>
      </div>
    );
  }

  return pageLoading ? (
    renderPageLoadingView()
  ) : (
    <div className=" items-center w-full">
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white  rounded-lg font-latoRegular"
      >
        <div className="flex-1 p-10 lg:p-20 ">
          <img
            src={formik.values.organizationSmallLogo}
            alt="react logo"
            className="w-40"
          />
          <h1 className="text-xl lg:text:4xl pb-2 font-bold ">
            {formik.values.organizationName}
          </h1>
          <h3 className="text-xl lg:text:2xl pb-2 font-bold ">
            {formik.values.title}
          </h3>
          <p className="text-md lg:text:lg text-gray-500">
            {formik.values.description}
          </p>
          <p className="text-sm lg:text:sm text-gray-700">
            {Moment(formik.values.createdAt).format(
              "dddd, MMMM Do YYYY, h:mm a"
            )}
          </p>

          {renderAttachments()}
          <div className="mt-5">
            {/* Answers input field*/}

            {renderPollOptions()}

            {/* comment input field*/}
            {formik.values.hide_comment_box ? (
              <div></div>
            ) : (
              <div className="pb-4 pt-5">
                <label
                  className="block font-bold text-sm pb-1 text-gray-500"
                  htmlFor="comment"
                >
                  Comment
                </label>
                <textarea
                  rows={4}
                  className="border-2 border-gray-400 p-2 rounded-md w-full focus:border-orange-500 focus:ring-orange-500"
                  name="comment"
                  placeholder="Enter your comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                />
              </div>
            )}

            {renderLastUpdate()}
          </div>

          {buttonView()}

          {
            <label className="block font-bold text-sm pt-3 text-center text-red-400">
              {renderPollError()}
            </label>
          }
        </div>
      </form>
    </div>
  );
}

export default PollDisplayer;
